import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { KeyValue } from '@angular/common';
import { Component, DestroyRef, HostListener, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconRegistry } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { FADE_BOTTOM, FADE_TOP, NAVBAR, VISIBLE_CHANGE } from 'src/app/helpers/animation';
import { adminPermission } from 'src/app/helpers/permission';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { GLOBAL_VAR } from 'src/app/models/global-variable';
import { IChildItem, IMenuItem, MENU } from 'src/app/models/menu-item';
import { AuthService } from 'src/app/services/auth.service';
import { CmsService } from 'src/app/services/cms.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProfileService } from 'src/app/services/profile.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateApiPipe } from 'src/app/shares/translate-api/translate-api.pipe';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  animations: [VISIBLE_CHANGE, FADE_TOP, FADE_BOTTOM, NAVBAR]
})
export class ContainerComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);
  readonly translate = inject(TranslateService);
  private readonly platform = inject(Platform);
  private readonly authService = inject(AuthService);
  private readonly userService = inject(UserService);
  private readonly profileService = inject(ProfileService);
  private readonly roleCheckerService = inject(RoleCheckerService);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly scrollService = inject(ScrollService);
  private readonly matIconRegistry = inject(MatIconRegistry);
  private readonly domSanitizer = inject(DomSanitizer);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly menuService = inject(CmsService);
  readonly translateAPI = inject(TranslateApiPipe);

  readonly social$ = this.menuService.getContact();

  pAdmin = adminPermission;

  routeName: string = this.router.url;
  browserLang: string;
  activeRoute!: string;
  loginRoute: string = GLOBAL_VAR.ROUTE.LOGIN;
  readonly routeUrl = GLOBAL_VAR.ROUTE;

  isExpanded = true;
  showSubmenu = false;
  isShowing = false;
  showSubSubMenu = false;
  isFirefox: boolean;
  mobileQuery!: boolean;
  smallScreen!: boolean;
  isAuth: boolean = this.authService.authStatus;
  isFullWidth: boolean = false;
  enableBgColor: boolean = !(this.routeName.includes('home') || this.routeName.includes('admin-service'));
  isScrollToBottom: boolean = false;

  sidebarMode: any;
  account: any;

  menuAdmin!: IMenuItem[];
  menu!: IMenuItem[];

  authUser!: object;
  langs: { [key: string]: string } = {
    'km': 'ខ្មែរ',
    'en': 'English'
  };

  /**
   * @config order of languages
   */
  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  };

  scrollHeight!: number;
  footerHeight!: number;
  toolbarHeight!: number;

  @ViewChild('sidenav') sidenav!: MatSidenav;

  @HostListener('window:scroll', ['$event']) onScroll(event: Event) {
    this.scrollHeight = (event.srcElement as HTMLElement)?.scrollTop;

    if (this.routeName.includes('home') || this.routeName.includes('admin-service')) {
      if (this.scrollHeight > 0) this.enableBgColor = true;
      else this.enableBgColor = false;
    }

    if (this.scrollHeight >= 600) this.isScrollToBottom = true;
    else this.isScrollToBottom = false;
  }

  constructor() {
    this.isFirefox = this.platform.FIREFOX;

    this.browserLang = this.localStorageService.get(LocalStorageEnum.language) || 'km';

    this.menuAdmin = MENU;
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-nav-icon-set.svg')
    );
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/action-icons.svg')
    );

    this.getMenuChild();

    this.initSidenav();
    if (this.authService.authStatus) {
      this.userService
        .getOne()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(data => {
          this.account = data;
          localStorage.setItem('account', JSON.stringify(data));
          this.profileService.staffId = data._id;
          this.initSidenav();
        });
    }

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        let path = this.router.url;
        if (path !== this.routeName) {
          this.enableBgColor = !(path.includes('home') || path.includes('admin-service'));
          this.routeName = path;
        }

        setTimeout(() => {
          this.scrollService.scrollToElementByIdNoSmooth('root-container');
        }, 0);
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.footerHeight = (<HTMLElement>document.querySelector('#footer'))?.offsetHeight || 0;
      this.toolbarHeight = (<HTMLElement>document.querySelector('#root-toolbar'))?.offsetHeight || 0;
    }, 0);
  }

  ngOnInit(): void {
    this.onSmallScreen();
    this.breakpointObserver.observe([Breakpoints.Large]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.mobileQuery = false;
      }
    });

    //update footer height change
    // this.changeDetectorRef.detectChanges();
  }

  scrollToTop(): void {
    this.scrollService.scrollToElementById('root-container');
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.browserLang = language;
    this.localStorageService.set(LocalStorageEnum.language, language);
  }

  initSidenav() {
    this.menu = [];
    for (let i = 0; i < this.menuAdmin.length; i++) {
      if (this.menuAdmin[i].child.length > 0) {
        let childs: IChildItem[] = [];
        for (let j = 0; j < this.menuAdmin[i]?.child.length; j++) {
          if (this.menuAdmin[i]?.child[j]?.permissions!.length > 0) {
            if (this.checkPermission(this.menuAdmin[i]?.child[j].permissions!)) {
              childs.push(this.menuAdmin[i].child[j]);
            }
          } else {
            childs.push(this.menuAdmin[i].child[j]);
          }
        }

        if (childs.length > 0) {
          let m: IMenuItem = JSON.parse(JSON.stringify(this.menuAdmin[i]));
          m.child = JSON.parse(JSON.stringify(childs));
          this.menu.push(m);
        }
      } else {
        // check permission menu without children
        if (this.menuAdmin[i].permissions!.length > 0) {
          if (this.checkPermission(this.menuAdmin[i].permissions!)) {
            this.menu.push(this.menuAdmin[i]);
          }
        } else {
          this.menu.push(this.menuAdmin[i]);
        }
      }
    }
  }

  public checkPermission(arr: string[]): boolean {
    let perms: string[] = this.roleCheckerService.GetPermissions();

    if (perms == undefined || perms.length <= 0) {
      return false;
    }
    for (let i = 0; i < arr.length; i++) {
      if (perms.filter(e => e == arr[i]).length > 0) {
        return true;
      }
    }
    return false;
  }

  isChildActive(childs: IMenuItem[] | IChildItem[]): boolean {
    for (let i = 0; i < childs.length; i++) {
      if (this.router.isActive(this.router.createUrlTree(childs[i].route), false)) {
        return true;
      }
    }
    return false;
  }

  onResize(): void {
    if (window.innerWidth <= 1360) {
      this.mobileQuery = true;
      this.isExpanded = false;
    }
    if (window.innerWidth > 1360) {
      this.isExpanded = false;
      this.mobileQuery = false;
    }
  }
  onSmallScreen(): void {
    if (window.innerWidth <= 1360) {
      this.mobileQuery = true;
      this.isExpanded = false;
    }
    if (window.innerWidth > 1360) {
      this.isExpanded = false;
      this.mobileQuery = false;
    }
  }

  toggleSideNav(): void {
    if (this.mobileQuery) {
      this.sidenav.toggle();
    } else {
      this.isExpanded = !this.isExpanded;
    }
  }

  getMenuChild(): void {
    this.menuService
      .getMenuChild()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        let childMenu = [];
        for (const element of data.department_service_levels) {
          childMenu.push({
            title: element.name,
            title_en: element.name_en,
            route: ['/admin-service/' + element._id],
            icon: '',
            svgIcon: '',
            permissions: []
          });
        }
        this.menu[1].child = childMenu;
      });
  }

  private mouseTimeout!: ReturnType<typeof setTimeout>;
  isHoverCard: boolean = false;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  onHover() {
    clearTimeout(this.mouseTimeout);
    this.isHoverCard = true;
    this.mouseTimeout = setTimeout(() => {
      if (this.isHoverCard) this.menuTrigger.openMenu();
    }, 100);
  }

  private mouseLeaveTimeout!: ReturnType<typeof setTimeout>;
  onLeave(): void {
    clearTimeout(this.mouseLeaveTimeout);
    this.isHoverCard = false;
    this.mouseLeaveTimeout = setTimeout(() => {
      if (!this.isHoverCard) this.menuTrigger.closeMenu();
    }, 100);
  }

  logout(): void {
    this.isAuth = false;
    this.authService.logout();
    this.router.navigateByUrl(this.loginRoute);
  }
}
